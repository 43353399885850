import React, { useState, useEffect, useRef } from 'react';
// import { useFetch } from '../../hooks';
import { render } from "react-dom";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
// import Indicators from "highcharts/indicators/indicators-all.js";
import DragPanes from "highcharts/modules/drag-panes";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced";
import PriceIndicator from "highcharts/modules/price-indicator";
import FullScreen from "highcharts/modules/full-screen";
import StockTools from "highcharts/modules/stock-tools";
// import "./style.css";

// init the module
// Indicators(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);

const FunViz = () => {

    const [symbol, setSymbol] = useState('aapl');
    const [multiple, setMultiple] = useState(15);
    const [change, setChange] = useState(0);
    const [before, setBefore] = useState(0);
    const [after, setAfter] = useState(0);
    const [query, setQuery] = useState('aapl');
    // const url = query && `https://demo-live-data.highcharts.com/${query}-c.json`;

    const url = query && `https://aubkfxjk61.execute-api.eu-central-1.amazonaws.com/prod/main/${query}`;

    const cache = useRef({});
    const [status, setStatus] = useState('idle');
    const [data, setData] = useState([]);

    const [chartOptions, setChartOptions] = useState({
            // events: {
            //   load: function() {
            //     let min = this.xAxis[0].min,
            //       max = this.xAxis[0].max;
      
            //     this.xAxis[0].plotLinesAndBands.forEach(el => {
            //       if (el.options.value > max) max = el.options.value;
            //       if (el.options.value < max) min = el.options.value;
            //     })
            //     console.log(min, max);
            //     this.xAxis[0].setExtremes(min, max);
            //   }
            // },
        yAxis: [
            {floor:0},
            {
                height: "80%",
            },
            {
                top: "80%",
                height: "20%",
                offset: 0,
                min: 0
            },
            // {
            // //     events: {
            // //     afterSetExtremes: function() {
            // //         const values = [],
            // //           chart = this.chart
            
            // //         this.chart.series[1].points.forEach(point => {
            // //           if (point.visible) values.push(point.x)
            // //         })
            // //         console.log(Math.max(...values))
            // //         chart.xAxis[1].setExtremes(Math.min(...values), Math.max(...values))
            // //       }
            // // }

            //   }

        ],
        xAxis: [{  crosshair: false, ordinal: false,
            // events: {
            //     afterSetExtremes: function() {
            //       this.chart.showResetZoom()
            //     }
            //   }
            }],
        chart: {height: "30%"},

        series: [
            {
            name: 'AAPL',
            findNearestPointBy: 'xy',
            zIndex: 100,
            lineWidth: 2,
            color: "black"
        }, 
        {
            name: 'EPS',
            findNearestPointBy: 'xy',
            type: 'areaspline',
            lineColor: "orange",
            lineWidth: 4,
            color: "green",
            marker: {
                enabled: true,
                radius: 5,
                symbol: 'triangle',
                fillColor: 'white',
                lineColor: 'black',
                lineWidth: 1,
            },
        }, {
            name: "performance",
            type: "line",
            dashStyle: 'LongDash',
            lineColor: "darkgrey",
            marker: {
                enabled: true,
                radius: 5,
                symbol: 'circle',
                fillColor: 'red',
                lineColor: 'red',
                lineWidth: 1,
            },
                
        }],
        tooltip: {
            shared: true,
        },
        plotOptions: {
            series: {
                states: {
                    hover: {lineWidthPlus: 0},
                    inactive: {enabled: false},
                },
                point: {
                    events: {
                        click: function (e) {

                            var chart = this.series.chart,
                            series = chart.series[2];

                            var y = e.point.y,
                            x = e.point.x;

                            if (series.points.length == 2) {
                                // console.log(this.series);
                                // for (let x in series.points) {
                                //     series.removePoint(0);
                                //   }
                                const asyncOperation = () => {
                                    return new Promise((resolve, reject) => {
                                        series.removePoint(0);
                                        series.removePoint(0);
                                        setTimeout(()=>{resolve("hi")}, 1);
                                    })
                                }
                                const asyncFunction = async () => {
                                    return await asyncOperation();
                                }
                                asyncFunction().then(series.addPoint([x, y]));
                                setAfter();setBefore(y);
                            } else if (series.points.length == 1) {
                                setAfter(y);
                                series.addPoint([x, y])
                            } else {
                                setBefore(y);
                                series.addPoint([x, y]);
                            }
                            
                        }
                    }
                }
            }
        },
        rangeSelector: {
            selected: 3,
            buttons: [
            // {
            //     type: 'month',
            //     count: 1,
            //     text: '1m',
            //     events: {
            //         click: function() {
            //             alert('Clicked button');
            //         }
            //     }
            // },
            {
                type: 'month',
                count: 6,
                text: '6m'
            }, {
                type: 'year',
                count: 1,
                text: '1y'
            }, {
                type: 'year',
                count: 5,
                text: '5y'
            }, {
                type: 'year',
                count: 10,
                text: '10y'
            }, {
                type: 'year',
                count: 15,
                text: '15y'
            }, {
                type: 'year',
                count: 20,
                text: '20y'
            }, {
                type: 'all',
                text: 'All'
            }]
        },

    });

    const fetchData = async () => {
        function applyMultiple(series)
        {
            var applyMultiple = function(x){
               return [x[0], x[1] * multiple];
            }
            
            var newArray = series.map(applyMultiple);
            // console.log( newArray );
            return newArray
        }
        var result = [];
        setStatus('fetching');
        console.log(`checking cache ${Math.floor(Math.random() * 100)}`)
        if (cache.current[url]) {
            console.log("fetching cache")
            const data = cache.current[url];
            setData(data);
            setStatus('fetched');
            result = data;
        } else {
            console.log("fetching api")
            await fetch(url)
            .then(response => response.json())
            .then(data => {
                cache.current[url] = data;
                setData(data);
                setStatus('fetched');
                result = data;
            })
            .catch((err) => {
                console.log(`Error fetching data for ${symbol}`)
                const data = [];
                // cache.current[url] = []; // set response in cache;
                setData(data);
                setStatus('error');
                result = [];
            });
            // try {
            //     console.log("fetching...")
            //     const response = await fetch(url);
            //     const data = await response.json();
            //     cache.current[url] = data; // set response in cache;
            //     setData(data);
            //     setStatus('fetched');
            // } catch (error) {
            //     console.log(`Error fetching data for ${url}`)
            //     const data = [];
            //     cache.current[url] = []; // set response in cache;
            //     setData(data);
            //     setStatus('fetched');
            // }
        }
        setChartOptions({
            series: [
                { data: result["price"] },
                { data: applyMultiple(result["fin"]) },
                {data: [[]]}
            ]
        })
        // console.log(cache.current[symbol])
    };

    const handleSymbolChange = event => {
        setSymbol(event.target.value);
        setQuery(event.target.value);
    
        console.log('value is:', event.target.value);
      };

    const handleMultipleChange = event => {
        setMultiple(event.target.value);
    
        console.log('value is:', event.target.value);
      };
    
    const handleClick = event => {
        event.preventDefault();
        setQuery(symbol);
        // 👇️ value of input field
        console.log('fetching for 👉️', symbol);
        const { status, data, error } = fetchData();
      };

    useEffect(() => {
        const { status, data, error } = fetchData();
    }, []);

    useEffect(() => {
        setChange(((after-before) / before * 100).toFixed(2));
    }, [after]);

    // useEffect(() => {
    //     const { status, data, error } = useFetch(url);
    //     updateDaily({"symbol":"AAPL"});
    //   }, []);

    // useEffect(() => {
    //     const { status, data, error } = useFetch(url)
    //     .then(setChartOptions({
    //         series: [
    //             { data: data }
    //         ]
    //     }))
    //   }, []);

    // const updateDaily = () => {
    //     fetch('https://demo-live-data.highcharts.com/aapl-c.json')
    //         .then((response) => response.json())
    //         .then(data => setChartOptions({
    //             series: [
    //                 { data: data }
    //             ]
    //         }));
    // }


    // useEffect(() => {
    //     const { status, data, error } = useFetch(url);
    //     updateDaily({"symbol":"AAPL"});
    //   }, []);

    //   useEffect runs by default after every render of the component (thus causing an effect).

    //   When placing useEffect in your component you tell React you want to run the callback as an effect. React will run the effect after rendering and after performing the DOM updates.

    //   If you pass only a callback - the callback will run after each render.

    //   If passing a second argument (array), React will run the callback after the first render and every time one of the elements in the array is changed. for example when placing useEffect(() => console.log('hello'), [someVar, someOtherVar]) - the callback will run after the first render and after any render that one of someVar or someOtherVar are changed.

    //   By passing the second argument an empty array, React will compare after each render the array and will see nothing was changed, thus calling the callback only after the first render.

    return (
        <div>
            <h2>FunViz</h2>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={"stockChart"}
                options={chartOptions}
            />
            {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
            <input
                type="text"
                id="symbol"
                name="symbol"
                onChange={handleSymbolChange}
                value={symbol}
                autoComplete="off"
            />
            <input
                type="text"
                id="multiple"
                name="multiple"
                onChange={handleMultipleChange}
                value={multiple}
                autoComplete="off"
            />

            <h2>Symbol: {symbol}</h2>
            <h2>Multiple: {multiple}</h2>
            <h2>Change: {change}%</h2>
            <h4>Before: {before}</h4>
            <h4>After: {after}</h4>

            <button onClick={handleClick}>Update</button>
        </div>
    );
}

export default FunViz;
