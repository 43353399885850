import React from 'react';
import logo from './logo.svg';
import './App.css';

import { API, graphqlOperation, Amplify} from 'aws-amplify';
// import { listNotes } from './graphql/queries';
// import { createNote, deleteNote } from './graphql/mutations';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import FunViz from './components/FunViz/FunViz';
import Layout from "./components/Layout/Layout";
import Home from "./components/Home/Home";
import Blogs from "./components/Blogs/Blogs";
import Contact from "./components/Contact/Contact";
import NoPage from "./components/NoPage/NoPage";

import awsconfig from './aws-config';

Amplify.configure(awsconfig);
console.log(awsconfig)

function App() {
  return (
    <div className="wrapper">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="funviz" element={<FunViz />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}


export default App;
